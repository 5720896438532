<template>
  <div class="login-wrapper">
    <div class="modal">
      <el-form ref="userForm" :model="user" status-icon :rules="rules" label-width="80px">
        <div class="title">票景通运营管理平台</div>
        <el-form-item label="用户名" prop="userName">
          <el-input type="text" prefix-icon="el-icon-user" v-model="user.userName"/>
        </el-form-item>
        <el-form-item label="密码" prop="userPwd">
          <el-input type="password" prefix-icon="el-icon-view" v-model="user.userPwd"/>
        </el-form-item>
        <!-- <el-form-item> -->
        <el-button type="primary" class="btn-login" @click="login">登录</el-button>
        <!-- </el-form-item> -->
      </el-form>
    </div>
  </div>
</template>

<script>
import { getAccessToken } from './../api/getway'
import { ElMessage,ElForm,ElInput,ElButton } from 'element-plus'
import { getConf } from './../utils/setting'
export default {
  name: 'login',
  components: {
    ElForm,
    ElFormItem:ElForm.FormItem,
    ElInput,
    ElButton
  },
  data () {
    return {
      user: {
        userName: '',
        userPwd: ''
      },
      rules: {
        userName: [
          {
            required: true, message: '请输入用户名', trigger: 'blur'
          }
        ],
        userPwd: [
          {
            required: true, message: '请输入密码', trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    login () {
      this.$refs.userForm.validate(async (valid) => {
        if (valid) {
          const conf = getConf()
          // console.log(conf)
          const res = await getAccessToken(this.user,conf)
          this.$store.commit('saveUserInfo', res)
          if (res.access_token) {
            const parkCode = JSON.parse(res.user_info).parkCode
            localStorage.setItem('parkCode',parkCode)
            ElMessage.success('登录成功!')
          }
          // this.$router.push('/welcome')
          this.$router.push('/tofu')
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss">
.login-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9fcff;
  width:100vw;
  height: 100vh;
  .modal{
    width:550px;
    padding: 60px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 3px #c7c9cb4d;
    .title{
      font-size: 40px;
      line-height: 1.5;
      text-align: center;
      margin-bottom: 30px;
    }
    .btn-login{
      width: 100%;
    }
  }
}

</style>
